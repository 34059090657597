import React from "react";

const Introuvable = () => {
  return (
    <div id="introuvable">
      <h1>404</h1>

      <p>Page introuvable</p>
    </div>
  );
};

export default Introuvable;
